import { Link } from "react-router-dom";

function NotFound() {
  return (
    <>
      <div className="em-c-hero  " >
        <div className="em-c-hero__body">
          <h1 className="em-c-hero__title" id="blockTitle">
            Terminal FastPass
          </h1>
          <p className="em-c-hero__desc" id="blockDesciption">
            Oops, the page that you are searching for not exists. <br/>
            Please, click <Link to="/" rel="home" className="em-c-header__title-link">HERE</Link> to go back to home.            
          </p>
        </div>
      </div>
    </>
  );
}
 
export default NotFound;