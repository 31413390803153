


export default function validationForm(
  tableValues,
  changeTable,
  FieldValues,
  changeField,
  operationValues,
  changeOperation,
  valueValues, 
  changeValues,  
  errorMessageValues,
  changeErrorMessages,
  resolutionMessageValues,
  changeResolutionMessage
) {
  const validationForm = [
    {
      type: "text",
      name: "table-name",
      label: "Table",
      placeholder: "Table",
      id: "table-name",
      value: tableValues,
      onChangeFunc: changeTable,
      enable: false,
    },
    {
      type: "text",
      name: "field",
      label: "Field",
      placeholder: "Field",
      id: "field",
      value: FieldValues,
      onChangeFunc: changeField,
      enable: false
    },
    {
      type: "text",
      name: "operation",
      label: "Operation",
      placeholder: "Operation",
      id: "operation",
      value: operationValues,
      onChangeFunc: changeOperation,
      enable: false
    },
    {
      type: "text",
      name: "value",
      label: "Value",
      placeholder: "Value",
      id: "value",
      value: valueValues,
      onChangeFunc: changeValues,
      enable: false
    },
    {
      type: "text",
      name: "error-message",
      label: "Error Message",
      placeholder: "Error Message",
      id: "error-message",
      value: errorMessageValues,
      onChangeFunc: changeErrorMessages,
      enable: true
    },
    {
      type: "text",
      name: "resolution-message",
      label: "Resolution Message",
      placeholder: "Resolution Message",
      id: "resolution-message",
      value: resolutionMessageValues,
      onChangeFunc: changeResolutionMessage,
      enable: true
    },
  ];
  return validationForm;
}
