const labels = []

export const ChartData = {
  labels: labels,
  datasets: [{
    label: 'Wait Time',
    data: [],
    backgroundColor: 'rgb(102, 0, 204)',  
    borderWidth: 1,
  }]
};

export const ChartOptions = {
    scales: {
      y: {
        display: true,
        position: 'right'
      }
    },
    plugins: {
        legend: {
            display: false
        }
    },
    layout: {
        padding: {
            left: 10,
            right: 10,
            top: 0,
            bottom: 10
        }
    },
    responsive: true,
    barThickness: 5,
}