import React from 'react';
import { SquareFill, TriangleFill, CircleFill } from 'react-bootstrap-icons'
import Input from './input';

const Table = ({header, values, content, type, selectFunc, selectedContent}) => {
    return (
        <>
            <div className="em-c-table-object ">
                <div className="em-c-table-object__body">
                    <div className="">
                        <table className="em-c-table" role="table">
                            <thead className="em-c-table__header ">
                                <tr className="em-c-table__header-row">
                                    {
                                        type === "selectable" ?
                                            <th scope="col" className="em-c-table__header-cell ">
                                                Select
                                            </th>
                                        :
                                            <></>
                                    }
                                    {
                                        header !== undefined ?
                                        header?.map(title => {
                                            return (
                                                <th scope="col" className="em-c-table__header-cell ">
                                                    {title}
                                                </th>
                                            );
                                        })
                                        :
                                        <></>
                                    }           
                                </tr>
                            </thead>
                            <tbody className="em-c-table__body">
                            {
                                content !== undefined ?
                                content?.map(row => {
                                    
                                    return (
                                        <tr className="em-c-table__row">
                                            {type === "selectable" ?
                                            <td className="em-c-table__cell em-js-cell em-js-cell-editable">
                                                <Input type="checkbox" label="select" value={row["id"]} onChangeFunc={selectFunc} checked={selectedContent.find(c => c.id == row["id"]) == undefined ? false : true} enable={row["available"] && row["availableByCustomerAndAccount"] && row["driverCanLoad"] && !row["expired"]}/>
                                            </td>
                                            :
                                            <></>}
                                        {values?.map( k => {
                                            return (
                                                <>
                                                {k !== "disruptionStatus" ?
                                                <td className="em-c-table__cell em-js-cell em-js-cell-editable">
                                                    {row[k]}
                                                </td>
                                                :
                                                row[k] === "Unavailable" ?
                                                    <td className="em-c-table__cell em-js-cell em-js-cell-editable">
                                                        <SquareFill className='icon--danger icon__status'/>{row[k]}
                                                    </td>
                                                :
                                                row[k] === "Limited" ?
                                                    <td className="em-c-table__cell em-js-cell em-js-cell-editable">
                                                        <TriangleFill className='icon--warning icon__status'/>{row[k]}
                                                    </td>
                                                :
                                                <td className="em-c-table__cell em-js-cell em-js-cell-editable">
                                                        <CircleFill className='icon--success icon__status'/>{row[k]}
                                                </td>}
                                                
                                                </>)}
                                        )}
                                        
                                            
                                        </tr>
                                    );
                                })
                                :
                                <></>
                            }   
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>        
        </>
        
    );
}

export default Table;