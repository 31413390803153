import { PlusCircleFill } from "react-bootstrap-icons";

const Button = (props) => {
  return (
    <button class="em-c-btn em-c-btn--primary     ">
        <PlusCircleFill className="em-c-icon em-c-icon--small em-c-btn__icon" />
        <span class="em-c-btn__text">{props.title}</span>
    </button>
  );
};

export default Button;
