import React from 'react';
const Loader = () => {
    return (
        <>
        <div
            className="em-c-loader loader-area"
            role="alert"
            aria-live="assertive"
            aria-busy="true"
            aria-label="Loading, please wait"
            >
            <svg id="test-animated-2" viewBox="0 0 100 100" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
<g id="test-animated-2-u-red_ts" transform="translate(50,50) scale(0,0)"><circle id="test-animated-2-u-red" r="50" transform="translate(0,0)" fill="#fff"/></g><g id="test-animated-2-u-white_ts" transform="translate(50,50) scale(0,0)"><circle id="test-animated-2-u-white" r="44" transform="translate(0,0)" fill="#112"/></g><g id="test-animated-2-u-blue_ts" transform="translate(50,50) scale(0,0)"><circle id="test-animated-2-u-blue" r="40" transform="translate(0,0)" fill="none" stroke="#fff" strokeWidth="2" strokeMiterlimit="10"/></g></svg>


        </div>
        </>
    );
}

export default Loader;