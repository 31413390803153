import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config/authConfig";
import Table from "../../elements/table";
import { GearFill, Trash3Fill } from "react-bootstrap-icons";
import Modal from "../../elements/modal";
import UserCreate from "../admin/UserCreate"
import PageHeader from "../../blocks/page_header";
import Loader from "../../elements/loader";
import Button from "../../elements/button"
import * as Icon from "react-bootstrap-icons";
import AdminUserUpdate from "./UserUpdate";
import API from "../../services/api";

const clientMobile = (new API()).api;

function UserActions(userId, instance, accounts, externalInternalId) {
  const removeUser = () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(request)
      .then((response) => {
        clientMobile
          .delete(`user?userId=${userId}&externalInternalId=${externalInternalId}`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              Authorization: "Bearer " + response.accessToken,
            },
          })
          .then((response) => {
            window.location.reload();
            return true;
          });
      })
      .catch((e) => {
        return false;
      });
  };

  return (
    <div className="em-c-btn-bar">
      <ul className="em-c-btn-bar__list">
        <li className="em-c-btn-bar__item" id="button-modal">
          <Modal 
            type="create-user"
            buttonOpenModal={
              <button className="em-c-btn  em-c-btn--small em-js-btn-selectable">
                <div className="em-c-btn__inner">
                  <GearFill className="em-c-icon em-c-icon--small em-c-btn__icon" />
                  <span className="em-c-btn__text">Update</span>
                </div>
              </button>
            }
            content={<AdminUserUpdate userId={userId} externalInternalId={externalInternalId} />}
            className="create-modal"
          />
        </li>
        <li className="em-c-btn-bar__item" id="button-modal">
          <Modal
            buttonOpenModal={
              <button className="em-c-btn  em-c-btn--small em-js-btn-selectable">
                <div className="em-c-btn__inner">
                  <Trash3Fill className="em-c-icon em-c-icon--small em-c-btn__icon" />
                  <span className="em-c-btn__text">Remove</span>
                </div>
              </button>
            }
            title={<div>Warning</div>}
            description="Are you sure you want to remove?"
            width="800px"
            type="information"
            functionality={() => removeUser(userId)}
            content=""
          />
        </li>
      </ul>
    </div>
  );
}

function AdminUser() {
  let tableHeader = [
    "Name",
    "Email",
    "Internal ID",
    "Okta ID",
    "Role",
    "Actions",
  ];
  let tableValues = [
    "name",
    "email",
    "internalCode",
    "externalCode",
    "userRole",
    "action",
  ];

  const { instance, accounts } = useMsal();

  const perPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [stateUsers, setUsers] = useState([]);
  const [stateOnLoading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");

  const getUsers = () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setLoading(true);
        clientMobile
          .get(`user/list?page=${currentPage}&perPage=${perPage}${search != "" ? `&query=${search}` : ""}`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              Authorization: "Bearer " + response.accessToken,
            },
          })
          .then((response) => {
            let data = response.data.users.map((x) => ({
              name: x.firstName + ", " + x.lastName,
              email: x.email,
              userRole: x.role,
              internalCode: x.internalCode,
              externalCode: x.externalCode,
              externalInternalId: x.externalInternalId,
              action: UserActions(x.id, instance, accounts, x.externalInternalId),
            }));
            setUsers(data);
            setTotalPages(Math.ceil(response.data.totalPages / perPage));
            setLoading(false);
          });
      })
      .catch((e) => {
        return false;
      });
  };

  const GoToPage = (page) => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setLoading(true);
        clientMobile
          .get(`user/list?page=${page}&perPage=${perPage}${search != "" ? `&query=${search}` : ""}`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              Authorization: "Bearer " + response.accessToken,
            },
          })
          .then((response) => {
            let data = response.data.users.map((x) => ({
              name: x.firstName + ", " + x.lastName,
              email: x.email,
              userRole: x.role,
              internalCode: x.internalCode,
              externalCode: x.externalCode,
              externalInternalId: x.externalInternalId,
              action: UserActions(x.id, instance, accounts, x.externalInternalId),
            }));
            setUsers(data);
            setTotalPages(Math.ceil(response.data.totalPages / perPage));
            setCurrentPage(page);
            setLoading(false);
          });
      })
      .catch((e) => {
        return false;
      });
  };

  const UserSearch = (query) => {
    setCurrentPage(0);

    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    let api =
      query != ""
        ? "user/list?page=0" + "&perPage=" + perPage + "&query=" + query
        : "user/list?page=0" + "&perPage=" + perPage;

    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setLoading(true);
        clientMobile
          .get(api, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              Authorization: "Bearer " + response.accessToken,
            },
          })
          .then((response) => {
            let data = response.data.users.map((x) => ({
              name: x.firstName + ", " + x.lastName,
              email: x.email,
              userRole: x.role,
              internalCode: x.internalCode,
              externalCode: x.externalCode,
              externalInternalId: x.externalInternalId,
              action: UserActions(x.id, instance, accounts, x.externalInternalId),
            }));
            setUsers(data);
            setTotalPages(Math.ceil(response.data.totalPages / perPage));
            setLoading(false);
          });
      })
      .catch((e) => {
        return false;
      });
  };

  function setSearchValue(evt) {
    setSearch(evt.target.value);
  }

  useEffect(() => {
    getUsers();
  }, [currentPage]);

  return (
    <>
      <PageHeader logged={true} />
      {stateOnLoading ? (
        <Loader />
      ) : (
        <div className="container em-u-padding-left-quad em-u-padding-right-quad ">
          <div className="em-l-grid em-l-grid--4up ">
            <div className="em-l-grid__item">
              <div className="fpo-block">
                <h1 className="em-u-padding-bottom-double em-u-padding-top-double em-u-text-align-left em-c-page-header__title text-blue-curious text-bold  fpo-block">
                  Users
                </h1>
              </div>
            </div>
            <div className="em-l-grid__item">
              <div className="fpo-block fpo-block--dark"></div>
            </div>
            <div className="em-l-grid__item mx-auto">
              <div className="fpo-block">
                <div
                  className=""
                  role="toolbar"
                  aria-label="Toolbar with search"
                >
                  <div className="em-c-toolbar__item em-c-toolbar__item--stretch">
                    <form
                      role="search"
                      className="em-c-search-form em-js-header-search em-c-search-form--small "
                      onSubmit={() => UserSearch(search)}
                    >
                      <div className="em-c-search__body">
                        <input
                          name="search"
                          id="search"
                          type="search"
                          className="em-c-search__input"
                          placeholder="Search..."
                          onChange={setSearchValue}
                          value={search}
                        />
                        <button
                          className="em-c-btn em-c-btn--primary em-c-btn--small    "
                          aria-label="Search"
                          type="submit"
                        >
                          <div className="em-c-btn__inner">
                            <Icon.Search></Icon.Search>
                          </div>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="em-l-grid__item mx-auto">
              <div className="fpo-block fpo-block--dark em-u-text-align-right">
                <Modal 
                    type="create-user"
                    buttonOpenModal={<Button title="New user"/>}
                    content={<UserCreate />}
                    className="create-modal"
                />
              </div>
            </div>
          </div>
          <div className="row em-u-padding-top-double ">
            <Table
              header={tableHeader}
              content={stateUsers}
              values={tableValues}
            />
          </div>
          <div className="pagination-container">
            {totalPages != 1 && totalPages != NaN && totalPages != 0 ? (
              <>
                {currentPage == 0 && totalPages != 1 && totalPages != 0 ? (
                  <>
                    <button className="em-c-btn em-c-btn--primary em-u-margin-double" onClick={() => GoToPage(currentPage + 1)}>
                      Next
                    </button>
                  </>
                ) : currentPage == totalPages && totalPages > 1 ? (
                  <>
                    <button className="em-c-btn em-c-btn--primary em-u-margin-double" onClick={() => GoToPage(currentPage - 1)}>
                      Previous
                    </button>
                  </>
                ) : (
                  <>
                    <button className="em-c-btn em-c-btn--primary em-u-margin-double" onClick={() => GoToPage(currentPage - 1)}>
                      Previous
                    </button>
                    <button className="em-c-btn em-c-btn--primary em-u-margin-double" onClick={() => GoToPage(currentPage + 1)}>
                      Next
                    </button>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default AdminUser;
