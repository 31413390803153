import React from 'react';
import { CaretRightFill, CaretDownFill } from 'react-bootstrap-icons';
import { useCollapse } from 'react-collapsed'

function Collapsible({type, title, content, classTypo, append}) {

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  
  return (
    <div className="collapsible">
        <div className={"header " + classTypo} {...getToggleProps()}>
            {title}

            <div className="append">
            {append !== undefined ? 
                append.map(append => {return append})
                : 
                <></>}
            </div>
        </div>
        <div>
            <div className="content">
                {content !== undefined ? 
                content.map(ct => {return ct})
                : 
                <></>}
            </div>
        </div>
    </div>
    );
}

export default Collapsible;