import React from 'react';
import ModalElement from './modal';

function WarningLine({title, description, resolution }) {

  return (
    <div className="warning-line">
        <div className="em-l-grid em-l-grid--2up ">
            <div className="em-l-grid__item">
                <div className="fpo-block">
                    <h2>{title}</h2>
                    <p>{description}</p>
                </div>
            </div>
            <div className="em-l-grid__item">
                <div className="fpo-block fpo-block--dark em-u-text-align-right">
                    {resolution}
                    <br/>
                    {   
                        resolution !== undefined ?
                            resolution.indexOf("cti.support") > -1 ?
                            <ModalElement title="Contact CTI Team" width="30vw" buttonOpenModal={<button className='em-c-btn'>Contact CTI Team</button>} content={<><p>Get in touch by:</p><p>Phone: 855-562-3427 </p> <p>E-mail: <p>US - cti.support@exxonmobil.com</p><p>Canada - CTI.Support-CA@esso.ca</p></p></>}/>
                            :
                            <></>
                        : 
                        <></>
                    }
                </div>
            </div>
        </div>
    </div>
    );
}

export default WarningLine;