
function PreLoadModel() {
    const preLoadModel = {
        termId: "",
        carrier: "",
        driver: "",
        vehicle: [],
        vehicleXRef: "",
        supplier: "",
        customer: "",
        account: "",
        destination: "",
        product: [],
        accountProduct: "",
        tank: "",
        arrival_date: "",
        typo: "string"
    }
    return preLoadModel
};

export default PreLoadModel;