import { useEffect } from "react";
import Card from "../elements/card";
import moment from 'moment'

const CardContainer = ({terminals}) => {
    useEffect(() => {
      }, [terminals])

    function convertDateTime(dt) {
        return moment.parseZone(dt).utc(true).format("YYYY/MM/DD hh:mm A")
    }
    
    function haveCurrentDisruption(dt){
        var currentDisruption = []
        if(dt !== undefined) {
            dt.forEach(d => {
                var date_now = moment(new Date()).format("YYYY/MM/DD kk:mm");
                var end_date = moment(d["end_dateUTC"]).format("YYYY/MM/DD kk:mm");
                var start_date = moment(d["start_dateUTC"]).format("YYYY/MM/DD kk:mm");
                if (start_date < date_now
                    && end_date > date_now) {
                    currentDisruption.push(d)
                }
            });
            return {response: currentDisruption.length > 0, value: currentDisruption}
        }
        return {response: currentDisruption > 0, value: currentDisruption}
    }

    function havePlannedDisruption(dt){
        var haveDisruption = false
        if(dt !== undefined) {
            dt.forEach(d => {
                var date_now = moment(new Date()).format("YYYY/MM/DD kk:mm");
                var start_date = moment(d["start_dateUTC"]).format("YYYY/MM/DD kk:mm");
                if (start_date > date_now) {
                    haveDisruption = true
                }
            });
            return haveDisruption
        }
        return haveDisruption
    }

    return(
        <>
        <div className="container">
            <div className="row">
                <div className="em-l-grid em-l-grid--1-to-3up ">
                {
                    terminals !== undefined ?
                    terminals.map(card =>{
                        return (<div className="em-l-grid__item" key={card.name}><div className="fpo-block"><Card name={card.name} address={card.address} disruption={haveCurrentDisruption(card.disruption).response ? haveCurrentDisruption(card.disruption).value[0] : havePlannedDisruption(card.disruption) ?  "Terminal Operating with planned disruption" : "Terminal Operating"} terminal_id={card.term_id} /></div></div>)
                    }) : (<></>)
                }
                </div>
            </div>
            
        </div>
        </>
    );
};

export default CardContainer;