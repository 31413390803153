const TerminalHeader = ({name, address}) => {
    return (
        <>
        <div className="container">
            <div className="row">
                <div className="em-c-page-header ">
                    <h1 className="em-c-page-header__title text-blue-curious text-bold">{name}</h1>
                    <p className="em-c-page-header__desc text-blue-curious">
                    {address}
                    </p>
                </div>
            </div>
        </div>
        
        </>
    );
}

export default TerminalHeader;