import { useState, useEffect } from "react";
import { loginRequest } from "../../config/authConfig";
import { useMsal } from "@azure/msal-react";
import { Link, useParams, useNavigate } from "react-router-dom";
import validationForm from "../../forms/validationForm";
import Form from "../../blocks/form";
import Loader from "../../elements/loader";
import ValidationModel from "../../models/ValidationsModel";
import TerminalHeader from "../../elements/terminal_header";
import { toast, ToastContainer } from "react-toastify";
import API from "../../services/api";

const clientMobile = (new API()).api;

function ValidationUpdate() {
  let { validationId } = useParams();

  let [stateOnLoading, setLoading] = useState(true);
  let [stateTableName, setTableName] = useState();
  let [stateField, setField] = useState();
  let [stateOperation, setOperation] = useState();
  let [stateValue, setValue] = useState();
  let [stateErrorMessage, setErrorMessage] = useState();
  let [stateResolutionMessage, setResolutionMessage] = useState();
  let [enableTable, setEnableTable] = useState(false);

  function setTableNameValue(evt) {
    setTableName(evt.target.value);
  }

  function setFieldValue(evt) {
    setField(evt.target.value);
  }

  function setOperationValue(evt) {
    setField(evt.target.value);
  }

  function setValueValue(evt) {
    setValue(evt.target.value);
  }

  function setErrorMessageValue(evt) {
    setErrorMessage(evt.target.value);
  }

  function setResolutionMessageValue(evt) {
    setResolutionMessage(evt.target.value);
  }

  function submitForm() {
    toast("Updating user...", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    let validationModel = new ValidationModel();
    validationModel.id = validationId;
    validationModel.errorMessage = stateErrorMessage;
    validationModel.resolutionMessage = stateResolutionMessage;

    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance.acquireTokenSilent(request).then((response) => {
      var form = validationModel;

      clientMobile
        .put("Validation/UpdateValidation", form, {
          headers: { Authorization: "Bearer " + response.accessToken },
        })
        .then(
          (response) => {
            toast.success("Success to update the user.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          },
          (error) => {
            toast.error("Error to edit the user. Try again later.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        );
    });
  }

  const { instance, accounts } = useMsal();

  const getValidations = () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(request)
      .then((response) => {
        clientMobile
          .get("Validation/ValidationById?validationId=" + validationId, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              Authorization: "Bearer " + response.accessToken,
            },
          })
          .then((response) => {
            setLoading(false);
            setTableName(response.data.tableName)
            setField(response.data.field)
            setOperation(response.data.operation)
            setValue(response.data.value)
            setErrorMessage(response.data.errorMessage)
            setResolutionMessage(response.data.resolutionMessage)
          });
        return true;
      })
      .catch((e) => {
        return false;
      });
  };

  useEffect(() => { getValidations() }, [])

  return (
    <>
      <>
        {stateOnLoading ? <Loader /> :
        <div className="container container-space">
          <div className="row">
           <TerminalHeader name={"Update Validation Rule"}/>
           <hr />
          </div>
          <div className="row alignment">
            <Form fields={validationForm(stateTableName, setTableNameValue, stateField, setFieldValue, stateOperation, setOperationValue, stateValue, setValueValue, stateErrorMessage, setErrorMessageValue, stateResolutionMessage, setResolutionMessageValue)} />
            <button onClick={submitForm} className="em-c-btn em-c-btn--primary btn--right em-u-margin-top-double" id="index-button">
              <span className="em-c-btn__text">Update</span>
            </button>

            <Link to="/admin/validationRules">
              <button className="em-c-btn em-c-btn--secondary btn--right em-u-margin-top-double" id="index-button">
                <span className="em-c-btn__text">Go back</span>
              </button>
            </Link>
          </div>
          <ToastContainer/>
        </div>
        }
      </> 
    </>
  );
}

export default ValidationUpdate;
