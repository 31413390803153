import * as React from 'react';
import BarChart from "../elements/charts";
import { ChartData, ChartOptions } from '../models/ChartDataModel';

const BarChartDate = ({labels, values}) => {
    let chartData = ChartData
    let chartOptions = ChartOptions

    chartData.labels = labels;
    chartData.datasets[0].data = values;

    return (
        <div>
            <BarChart chartData={chartData} chartOptions={chartOptions}/>
        </div>
      );
}

export default BarChartDate