import * as Icon from 'react-bootstrap-icons';
import React from 'react'
class Search extends React.Component{
    
    onTrigger = (event) => {
        var search = event.target.search.value
        this.props.callback(search);
        event.preventDefault();
    }
  
    render(){
        return(
          <>
          <div className="" role="toolbar" aria-label="Toolbar with search">
            <div className="em-c-toolbar__item em-c-toolbar__item--stretch">
              <form
                role="search"
                className="em-c-search-form em-js-header-search em-c-search-form--small "
                onSubmit={this.onTrigger}
              >
                <div className="em-c-search__body">
                  <input
                    name="search"
                    id="search"
                    type="search"
                    className="em-c-search__input"
                    placeholder={this.props.placeholder != null ? this.props.placeholder : "Search..."}
                  />
                  <button
                    className="em-c-btn em-c-btn--primary em-c-btn--small    "
                    aria-label="Search"
                    type='submit'
                  >
                    <div className="em-c-btn__inner">
                      <Icon.Search></Icon.Search>
                    </div>
                  </button>
                </div>
              </form>
            </div>
          </div>
          </>
        )
    }
}
export default Search