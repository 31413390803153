import { CaretRightFill } from "react-bootstrap-icons";
import { NavLink, Link } from "react-router-dom";
import moment from 'moment'
import { loginRequest } from "../config/authConfig";
import { useMsal} from '@azure/msal-react';
import API from "../services/api";

function convertDateTime(dt) {
    return moment.parseZone(dt).utc(true).format("YYYY/MM/DD hh:mm A")
}



const clientTerminal = (new API()).api;
const cleanForm = () => {
}
// const { instance, accounts } = useMsal();

// const getBol = (id) => {
//     const request = {
//       ...loginRequest,
//       account: accounts[0]
//     };
//     debugger

//     instance.acquireTokenSilent(request).then((response) => {
//       clientTerminal.get('bol/get?id=' + id, {headers: {"Access-Control-Allow-Origin": "*", "Authorization" : "Bearer " + response.accessToken}}).then((response) => {
//         debugger;
//         setBolPDFUrl(response.data);
//       });
//       return true
//     }).catch((e) => {
//       return false
//     });
//       return true
//     };

const Card = ({name, address, disruption, terminal_id, type, title, information, action, meta=null, id=null, prepare_file_action=null}) => {
    if (type == "generic") {
        return (
            <>
            <article
                className="em-c-solid-card"
                aria-labelledby="cardTitle-:r24g:"
                aria-describedby="cardDescription-:r24g:"
                >
                <div className={"border-success em-c-solid-card__body"}>
                    <div className="em-c-solid-card__title em-u-margin-bottom text-indigo" id="cardTitle-:rs1:">
                    {title}
                    </div>
                
                    <div className="card__description" id="cardDescription-:rs1:">
                    {information}
                    </div>
                </div>
                <Link to={action}>
                    <div className="em-c-solid-card__footer">
                        <span className="text-card">
                            Check
                        </span>
                        <span className="icon-right">
                        <CaretRightFill></CaretRightFill>
                        </span>
                    </div>
                    </Link>
                </article>
            </>
        );
    } else if (type == "bol") { 
        return (
            <>
            <article
                className="em-c-solid-card"
                aria-labelledby="cardTitle-:r24g:"
                aria-describedby="cardDescription-:r24g:"
                >
                <div className={"border-success em-c-solid-card__body"}>
                    <div className="em-c-solid-card__title em-u-margin-bottom text-indigo" id="cardTitle-:rs1:">
                    {title}
                    </div>
                
                    <div className="card__description" id="cardDescription-:rs1:">
                    {information}
                    </div>
                </div>
                    <span onClick={prepare_file_action}>
                        <div className="em-c-solid-card__footer">
                            <span className="text-card">
                                Prepare File
                            </span>
                            <span className="icon-right">
                            <CaretRightFill></CaretRightFill>
                            </span>
                        </div>
                    </span>
                </article>
            </>
        );
    } else {
        return (
            <>
            <article
                className="em-c-solid-card"
                aria-labelledby="cardTitle-:r24g:"
                aria-describedby="cardDescription-:r24g:"
                >
                <div className={disruption === "Terminal Operating" ? "border-success em-c-solid-card__body" : disruption === "Terminal Operating with planned disruption" ? "border-warning em-c-solid-card__body" : "border-error em-c-solid-card__body"}>
                    <div className="em-c-solid-card__title em-u-margin-bottom text-indigo" id="cardTitle-:rs1:">
                    {name}
                    </div>
                
                    <div className="card__description" id="cardDescription-:rs1:">
                    {address}
                    </div>
                    <br />
                    <span className=" text-indigo text-bold">Realtime status</span>
                    <br />
                    { disruption === "Terminal Operating" ? "Terminal Operating" 
                        : disruption === "Terminal Operating with planned disruption" 
                        ? "Terminal Operating with planned disruption" 
                        :  "From " + convertDateTime(disruption["start_dateUTC"])
                        + " To " + convertDateTime(disruption["end_dateUTC"]) }
                    
                </div>
                <NavLink to={ "/terminal/" + terminal_id }>
                <div className="em-c-solid-card__footer">
                        <span className="text-card">
                            Check Details
                        </span>
                        <span className="icon-right">
                        <CaretRightFill></CaretRightFill>
                        </span>
                    </div>
                </NavLink>
                </article>
            </>
        );
    }
}

export default Card;