
function WaitTimeModel() {
    const waitTimeModel = {
        termId: "00006SD",
        startTime: "2023-06-15T00:00:00.000Z",
        endTime: "2023-06-16T00:00:00.000Z"
    }
    return waitTimeModel
};

function WaitTimeChartModel() {
    const waitTimeModel = {
        labels: [],
        values: []
    }
    return waitTimeModel
};

export default WaitTimeChartModel
