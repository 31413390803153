import { useState, useEffect } from "react";
import CardContainer from "../../blocks/card_container";
import PageHeader from "../../blocks/page_header";
import Loader from "../../elements/loader";
import { loginRequest } from "../../config/authConfig";
import { useMsal } from "@azure/msal-react";
import Table from "../../elements/table";
import Card from "../../elements/card";
import { GearFill, PlusCircleFill, Trash3Fill } from "react-bootstrap-icons";
import { Link, useRouteError, useParams } from "react-router-dom";
import Modal from "../../elements/modal";
import API from "../../services/api";

const clientMobile = (new API()).api;

function ValidationActions(id, instance, accounts) {
  const removeValidation = () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(request)
      .then((response) => {
        clientMobile
          .delete("Validation/DeleteValidation/" + id, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              Authorization: "Bearer " + response.accessToken,
            },
          })
          .then((response) => {
            location.reload()
            return true;
          });
      })
      .catch((e) => {
        return false;
      });
  };

  return (
    <div className="em-c-btn-bar em-u-margin-right-quad fpo-block em-u-display-flex">
      <ul className="em-c-btn-bar__list ">
        <li className="em-c-btn-bar__item">
          <Link to={"update/" + id}>
            <button className="em-c-btn  em-c-btn--small em-js-btn-selectable   ">
              <div className="em-c-btn__inner">
                <GearFill className="em-c-icon em-c-icon--small em-c-btn__icon"></GearFill>
                <span className="em-c-btn__text">Edit</span>
              </div>
            </button>
          </Link>
        </li>
        {/*<li className="em-c-btn-bar__item" id="button-modal">
          <Modal
            buttonOpenModal={
              <button
                className="em-c-btn  em-c-btn--small em-js-btn-selectable"
              >
                <div className="em-c-btn__inner">
                  <Trash3Fill className="em-c-icon em-c-icon--small em-c-btn__icon"></Trash3Fill>
                  <span className="em-c-btn__text">Remove</span>
                </div>
              </button>
            }
            title={<div>
              Warning
            </div>}
            description="Are you sure you want to remove?"
            width="800px"
            type="information"
            functionality={() => removeValidation(id)}
            content=""
          />
            </li>*/}
      </ul>
    </div>
  );
}

function AdminValidations() {
  let tableHeader = ["Table", "Field", "Operation", "Value", "Error Message", "Resolution Message", "Actions"];
  let tableValues = ["tableName", "field", "operation", "value", "errorMessage", "resolutionMessage", "action"];

  var [stateValidations, setValidations] = useState();
  var [stateOnLoading, setLoading] = useState(true);

  const { instance, accounts } = useMsal();

  const getValidations = () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setLoading(true);
        clientMobile
          .get("Validation/Validations", {
            headers: {
              "Access-Control-Allow-Origin": "*",
              Authorization: "Bearer " + response.accessToken,
            },
          })
          .then((response) => {
            setValidations(
              response.data.map((x) => ({
                tableName: x.tableName,
                field: x.field,
                operation: x.operation,
                value: x.value,
                type: x.type,
                errorMessage: x.errorMessage,
                resolutionMessage: x.resolutionMessage,
                action: ValidationActions(x.id, instance, accounts),
              }))
            );
            setLoading(false);
          })
        return true;
      })
      .catch((e) => {
        return false;
      });
  };

  useEffect(() => {
    getValidations();
  }, []);

  return (
    <>
      <>
        <PageHeader logged={true} />
        {stateOnLoading ? (
          <Loader />
        ) : (
          <div className="container em-u-padding-left-quad em-u-padding-right-quad ">
            <div class="em-l-grid em-l-grid--4up ">
              <div class="em-l-grid__item">
                <div class="fpo-block">
                  <h1 className="em-u-padding-bottom-double em-u-padding-top-double em-u-text-align-left em-c-page-header__title text-blue-curious text-bold  fpo-block">Validation Rules</h1>
                </div>
              </div>
            </div>
            <div className="row em-u-padding-top-double ">
              <Table
                header={tableHeader}
                content={stateValidations}
                values={tableValues}
              />
            </div>
          </div>
        )}
      </>
    </>
  );
}

export default AdminValidations;
