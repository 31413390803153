import Header from '../blocks/header'
import Footer from '../blocks/footer'

import { loginRequest } from "../config/authConfig";
import {
  useMsal,
} from '@azure/msal-react';
import PageHeader from "../blocks/page_header";
import { redirect } from 'react-router-dom';
import { useState, useEffect } from "react";

function Loggin(){
  
  const { instance, accounts  } = useMsal();

  var [stateLoggedIn, setLoggedIn] = useState(false);

  function handleLogin(loginType){
    if (loginType === "popup") {
      instance.loginPopup(loginRequest).catch(e => {
        console.log(e);
      });
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch(e => {
        console.log(e);
      });
    }
  }

  useEffect(() => { 
    const request = {
      ...loginRequest,
      account: accounts[0]
    };
    instance.acquireTokenSilent(request).catch(e => {
      instance.loginRedirect(loginRequest)
    });
   }, [])

  return (
    <>
      <div className="logged-out">
        <Header handleLogin={() => handleLogin("redirect")} />
        <PageHeader logged={false} logginAction={() => handleLogin("redirect")}/>
        <Footer />
      </ div>
    </>
  )
};

export default Loggin;