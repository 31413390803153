
function WaitTimeForm(changeDatetime) {
    const waitTimeForm = [
        {
            type: "datetime",
            name: "Wait-time-field",
            label: "Select a date",
            placeholder: "Select a date",
            id: "Wait-time-field",
            value: [],
            onChangeFunc: changeDatetime
        }
    ]
    return waitTimeForm
  };
  
  export default WaitTimeForm;