import { useState, useEffect } from "react";
import CardContainer from "../blocks/card_container";
import Toolbar from "../blocks/toolbar";
import PageHeader from "../blocks/page_header";
import Loader from "../elements/loader";
import { loginRequest } from "../config/authConfig";
import { useMsal } from '@azure/msal-react';
import API from "../services/api";

const clientMobile = (new API()).api;


function Index() {
  var [stateTerminals, setTerminals] = useState();
  var [stateMainTerminals, setMainTerminals] = useState();
  var [stateOnLoading, setLoading] = useState();
  
  const { instance, accounts } = useMsal();

  const getTerminals = () => {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    instance.acquireTokenSilent(request).then((response) => {
      setLoading(true);
      clientMobile.get('Web/GetTerminalsDisruption', {headers: {"Access-Control-Allow-Origin": "*", "Authorization" : "Bearer " + response.accessToken}}).then((response) => {
        setTerminals(response.data);
        setMainTerminals(response.data);
        setLoading(false);
      });
      return true
    }).catch((e) => {
      return false
    });
  }

  function TerminalSearch(terminals){
    var search_terminals = []
    if(terminals != "") {
      debugger
      search_terminals = stateMainTerminals.filter(terminal => terminal.name.replace(/\s/g, "").toLowerCase().includes(terminals.replace(/\s/g, "").toLowerCase()));
      setTerminals(search_terminals)
    } else {
      setTerminals(stateMainTerminals);
    }
    return search_terminals;
  }

  useEffect(() => { getTerminals(); }, [])
  
  return (
    <>
      <>
        <PageHeader logged={true}/>
        <Toolbar callback={TerminalSearch} />
        {stateOnLoading ? <Loader /> : <CardContainer terminals={stateTerminals}/>}
      </> 
    </>
  );
}
 
export default Index;