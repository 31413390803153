import PageHeader from "../../blocks/page_header";
import Card from "../../elements/card";
import API from "../../services/api";

function AdminIndex() {
  return (
    <>
      <>
      <PageHeader logged={true}/>
        <div className="container em-u-padding-left-quad em-u-padding-right-quad">
          <h1 className="em-u-padding-bottom-double em-u-padding-top-double em-u-text-align-center em-c-page-header__title text-blue-curious text-bold  fpo-block">Administration Settings</h1>
          <div className="row">
            <div className="em-l-grid em-l-grid--4up ">
            <div className="em-l-grid__item"></div>
            <div className="em-l-grid__item"><div className="fpo-block"><Card type="generic" title="Users" action="/admin/user"/></div></div>
            <div className="em-l-grid__item"><div className="fpo-block"><Card type="generic" title="Validation Rules" action="/admin/validationRules"/></div></div>
            <div className="em-l-grid__item"></div>
            </div>
          </div>
        </div>
      </> 
    </>
  );
}
 
export default AdminIndex;