function UserModel() {
    const userModel = {
        firstName: "",
        lastName: "",
        company: "",
        phoneNumber: "",
        phoneCountryCode: "",
        email: "",
        role: "",
        externalCode: "",
        internalCode: "",
        terminals: []
    }

    return userModel
}

export default UserModel;