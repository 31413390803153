import Input from "../elements/input";

function Form({name, fields}) {
    return (
      <>
      <form className="em-l-grid em-l-grid--1-to-3up" name={name} id={name}>
        {
        fields !== undefined ?
        fields.map( field => {
            return <div className="em-l-grid__item"><div className="fpo-block"><Input enable={field.enable} defaultValue={field.defaultValue} isMultiple={field.isMultiple} limit={field.limit} name={field.name} type={field.type} label={field.label} placeholder={field.placeholder} id={field.id} onChangeFunc={field.onChangeFunc} value={field.value} selectedValue={field.selectedValue} /></div></div>
        })
        :
        <></>
    }
    </form>
      </>
    )
  };
  
  export default Form;