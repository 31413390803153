function ValidationsModel() {
    const validationModel = {
        tableName: "",
        field: "",
        operation: "",
        value: "",
        errorMessage: "",
        resolutionMessage: ""
    }

    return validationModel
}

export default ValidationsModel;