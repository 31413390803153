const PageHeader = ({logged, logginAction}) => {
    return (
      logged ?
      <>
        <div className="em-c-hero  " >
          <div className="em-c-hero__body hero-title">
            <h1 className="em-c-hero__title" id="blockTitle">
              Terminal FastPass
            </h1>
          </div>
        </div>
      </>
      :
      <>
        <div className="em-c-hero  " >
          <div className="em-c-hero__body">
            <h1 className="em-c-hero__title" id="blockTitle">
              Terminal FastPass
            </h1>
            <p className="em-c-hero__desc" id="blockDesciption">
              To find your products easily.
            </p>
          </div>
        </div>
      </>
    )
  };
  
  export default PageHeader;